import React from "react";
import styles from "../styles/Home.module.scss";
import homeStr from "@assets/strings/home.json";
import MainActivityCard from "./MainActivityCard";
import { Languages } from "utils/datautils";
import { slugMapping, SolutionSection } from "utils/mainActivityUtils";
import Link from "next/link";

type MainActivitySectionProps = {
  lang: Languages;
  solution: SolutionSection[];
};

const MainActivitySection = (props: MainActivitySectionProps) => {
  return (
    <div
      id="solution-section"
      className={`container ${styles.solution_section} `}
    >
      <div className={styles.solution_section_title}>
        {homeStr.solution_title[props.lang]}
      </div>
      <div className={styles.solution_section_desc}>
        {homeStr.solution_subtitle[props.lang]}
      </div>

      <div className={styles.activity_card_container}>
        {props.solution.map((item, idx) => {
          const mappedSlug = slugMapping[item.attributes.activity_name];
          return (
            <Link key={`activity-${idx}`} href={`/${mappedSlug}`} passHref>
              <a style={{ textDecoration: "none" }}>
                <MainActivityCard
                  activityName={item.attributes.activity_name}
                  activityIcon={
                    item.attributes.activity_icon?.data?.attributes?.url
                  }
                  activityIconHover={
                    item.attributes.activity_icon_hover?.data?.attributes?.url
                  }
                  lang={props.lang}
                />
              </a>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MainActivitySection;
