import Link from "next/link";
import styles from "../styles/RcsClients.module.scss";
import str from "@assets/strings/home.json";
import { useRef, useState } from "react";
import { Languages } from "utils/datautils";
import { ClientsData } from "utils/mainActivityUtils";

interface RcsProps {
  lang: Languages;
  className: string;
  rcsClient: ClientsData[];
}

export default function RcsClients({ lang, className, rcsClient }: RcsProps) {
  const clientsLogo = useRef<HTMLDivElement>(null);
  const [clients, setClients] = useState<string[]>([
    "/assets/clients/Vale.png",
    "/assets/clients/PGE.png",
    "/assets/clients/Beraucoal.png",
    "/assets/clients/Pamapersada.png",
    "/assets/clients/ABM.png",
    "/assets/clients/Mitsui.png",
    "/assets/clients/Telkom.png",
    "/assets/clients/Ikea.png",
    "/assets/clients/IFG.png",
    "/assets/clients/Haleon.png",
    "/assets/clients/Pegadaian.png",
    "/assets/clients/Traveloka.png",
    "/assets/clients/Indolaw.png",
  ]);
  return (
    <div className={className}>
      <p className={styles.rcs_client_title}>{str.clients[lang]}</p>
      <div ref={clientsLogo} className={`flex ${styles.clients_logo}`}>
        {rcsClient.map((cl, idx) => {
          return (
            <div
              key={`clients-${idx}`}
              className={`${styles.clients_img_container}`}
            >
              <img
                src={cl.client_image}
                alt={`${cl}.img`}
                className={`clients-${idx}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
