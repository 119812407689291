import React from "react";
import { Languages } from "utils/datautils";
import styles from "styles/main-activity/MainActivityBenefitCard.module.css";

type MainActivityBenefitCardProps = {
  icon: React.ReactNode;
  iconHover: React.ReactNode;
  title: string;
  desc: string;
  lang: Languages;
};

const MainActivityBenefitCard = (props: MainActivityBenefitCardProps) => {
  return (
    <div className={styles.main_activity_benefit_card}>
      <div
        className={`${styles.main_activity_benefit_icon} ${styles.default_icon}`}
      >
        {props.icon}
      </div>
      <div
        className={`${styles.main_activity_benefit_icon} ${styles.hover_icon}`}
      >
        {props.iconHover}
      </div>
      <p className={`${styles.main_activity_benefit_title}`}>{props.title}</p>
      <p className={`${styles.main_activity_benefit_desc}`}>{props.desc}</p>
    </div>
  );
};

export default MainActivityBenefitCard;
