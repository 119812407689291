import Head from "next/head";
import { ReactElement, useEffect, useRef, useState } from "react";
import {
  Languages,
  NextPageWithLayout,
  TestimonialsData,
} from "../utils/datautils";
import { headline } from "utils/articlesAndInsights";
import { UseCase } from "utils/usecase";
import MainLayout from "../layouts/MainLayout";
import homeStr from "@assets/strings/home.json";
import benefitStr from "@assets/strings/benefit.json";
import articleData from "@assets/articles/data";
import articleStr from "@assets/strings/article.json";

import styles from "../styles/Home.module.scss";
import RcsVideo from "../components/RcsVideo";
import RequestDemo from "components/RequestDemo";
import BookDemoModal from "components/BookDemoModal";
import FloatWhatsapp from "components/FloatWhatsapp";
import ValueProposition from "components/ValueProposition";
import LearnMoreText from "components/LearnMoreText";
import Image from "next/image";
import Link from "next/link";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { getUseCaseHeadline } from "services/useCase";
import { getHomeArticles } from "services/articlesAndInsights";
import getBanners from "services/bannerHomePage";
import BannerSlider from "components/BannerSlider";
import { banner } from "utils/banner";
import { useRouter } from "next/router";
import RcsClients from "components/RcsClients";
import getTestimonials from "services/testimonials";
import { ClientsData, SolutionSection } from "utils/mainActivityUtils";
import getMainActivity from "services/mainActivity";
import getClientLogos from "services/clientLogos";
import MainActivitySection from "components/MainActivitySection";
import BenefitComponent from "components/BenefitComponent";
import TestimonialsComponent from "components/TestimonialsComponent";

type IHomeProps = {
  lang: Languages;
  requestDemo: boolean;
  onUpdateReqDemo: Function;
  rcsClient: ClientsData[];
  useCase: UseCase;
  articles: headline[];
  banners: banner[];
  solution: SolutionSection[];
  testimonials: TestimonialsData[];
};

const Home: NextPageWithLayout<IHomeProps> = (props: IHomeProps) => {
  const [page, setPage] = useState(0);
  const [counter, setCounter] = useState(0);
  const [bookModal, setBookModal] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);
  const heroContent = useRef<HTMLDivElement>(null);
  const clientsLogo = useRef<HTMLDivElement>(null);
  const pageRef = useRef<number>(0);
  const slideRef = useRef<any>();
  const pageBenefitRef = useRef<number>(0);
  const [clients, setClients] = useState<string[]>([
    "/assets/clients/Vale.png",
    "/assets/clients/PGE.png",
    "/assets/clients/Beraucoal.png",
    "/assets/clients/Pamapersada.png",
    "/assets/clients/ABM.png",
    "/assets/clients/Mitsui.png",
    "/assets/clients/Astrapay.png",
    "/assets/clients/Bukalapak.png",
    "/assets/clients/Telkom.png",
  ]);
  const [bannerSwipePos, setBannerSwipePos] = useState(0);
  const [benefitPage, setBenefitPage] = useState(0);
  const [benefitSwipePos, setBenefitSwipePos] = useState(0);
  const [testimonialPos, setTestimonialPos] = useState(0);

  pageRef.current = page;
  pageBenefitRef.current = benefitPage;
  const router = useRouter();

  const benefitContent = [
    {
      img: "/assets/intuitive-dashboard.png",
      pageName: benefitStr.page_name1[props.lang],
      title: benefitStr.item_title1[props.lang],
      description: benefitStr.item_desc1[props.lang],
    },
    {
      img: "/assets/automatic-content-update.png",
      pageName: benefitStr.page_name2[props.lang],
      title: benefitStr.item_title2[props.lang],
      description: benefitStr.item_desc2[props.lang],
    },
    {
      img: "/assets/compliance-monitor.png",
      pageName: benefitStr.page_name3[props.lang],
      title: benefitStr.item_title3[props.lang],
      description: benefitStr.item_desc3[props.lang],
    },
  ];

  const nextPage = () => {
    if (heroContent.current) heroContent.current.classList.remove("ease-in");
    if (clientsLogo.current) clientsLogo.current.classList.remove("slide-in");
    const next =
      pageRef.current === props.banners.length - 1 ? 0 : pageRef.current + 1;

    setPage(next);
    slideRef?.current?.goTo(next);
  };

  const nextBenefitPage = () => {
    const totalPage = benefitContent.length;
    const nextPage =
      pageBenefitRef.current === totalPage - 1
        ? 0
        : pageBenefitRef.current > totalPage - 1
        ? 0
        : benefitPage + 1;

    slideRef?.current?.goTo(nextPage > totalPage - 1 ? 0 : nextPage);
    setBenefitPage(nextPage > totalPage - 1 ? 0 : nextPage);
  };

  const nextLogo = () => {
    if (clientsLogo.current && clients.length > 3) {
      const ctemp = [...clients];
      const first = ctemp.shift();
      if (first) {
        ctemp.push(first);
        setClients(ctemp);
      }
    }
  };

  const backPage = () => {
    if (heroContent.current) heroContent.current.classList.remove("ease-in");
    if (clientsLogo.current) clientsLogo.current.classList.remove("slide-in");
    const next = page === 0 ? props.banners.length - 1 : page - 1;
    setPage(next);
  };

  const backBenefitPage = () => {
    const totalPage = benefitContent.length;
    const nextPage = benefitPage === 0 ? totalPage - 1 : benefitPage - 1;

    slideRef?.current?.goTo(nextPage);
    setBenefitPage(nextPage);
  };

  useEffect(() => {
    if (heroContent.current) heroContent.current.classList.add("ease-in");
    // if (clientsLogo.current && clients.length > 3)
    //   clientsLogo.current.classList.add("slide-in");
    const id = setTimeout(() => {
      nextPage();
      nextBenefitPage();
      // nextLogo();
      setCounter(counter + 1);
    }, 10000);
    return () => {
      clearTimeout(id);
    };
  }, [counter]);

  const modalControl = () => {
    setBookModal(!bookModal);
    props.onUpdateReqDemo();
  };

  useEffect(() => {
    if (props.requestDemo !== bookModal) setBookModal(props.requestDemo);
  }, [props.requestDemo]);

  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
      window.addEventListener("resize", () => {
        setScreenHeight(window.innerHeight);
        setScreenWidth(window.innerWidth);
      });
    }
  }, []);

  const getJustifyStyle = () => {
    if (articleData && articleData.length > 4) {
      return styles.justify_flex_start;
    }
    return "";
  };

  /**
   * @param endXPosition end of the user's touch
   * compared to the initial position saved in benefitSwipePos
   * if the displacement is bigger than 70px than it will swipe wether left or right
   * if you want to add animation on it add onTouchMove and play with the animation there
   */
  const calculateSwipe = (endXPosition: number) => {
    let { isMove, finalPos } = calculateNextSwap(
      benefitPage,
      benefitSwipePos,
      endXPosition
    );
    if (isMove) setBenefitPage(finalPos);
  };

  const calculateBannerSwipe = (endXPosition: number) => {
    let { isMove, finalPos } = calculateNextSwap(
      page,
      bannerSwipePos,
      endXPosition
    );
    if (isMove) setPage(finalPos);
  };

  const calculateNextSwap = (
    target: number,
    startXPos: number,
    endXPosition: number
  ) => {
    let isMove = false;
    let finalPos: number = 0;
    const calculateDiff = endXPosition - startXPos;
    if (Math.abs(calculateDiff) > 70) {
      if (calculateDiff > 0) {
        /** swipe left */
        const backPage = target > 0 ? target - 1 : target;
        isMove = true;
        finalPos = backPage;
      } else {
        /** swipe right */
        const nextPage =
          target < benefitContent.length - 1 ? target + 1 : target;
        isMove = true;
        finalPos = nextPage;
      }
    }
    return { isMove, finalPos };
  };
  const handleSlideChange = (previous: number, next: number) => {
    setBenefitPage(next);
  };
  return (
    <div className={styles.main_container}>
      <Head>
        <title>{homeStr.metaTitle[props.lang]}</title>
        <meta property="og:title" content={homeStr.metaTitle[props.lang]} />
        <meta
          name="description"
          content={homeStr.metaDescription[props.lang]}
        />
        <meta
          property="og:description"
          content={homeStr.metaDescription[props.lang]}
        />
        <meta name="keywords" content={homeStr.metaKeywords[props.lang]} />
        <meta
          property="og:image"
          content="https://drive.google.com/uc?export=view&id=1GbYuCfFIWa71GWEh30tuOVf3ImIaP5uo"
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>
        <div className={styles.background_wrapper}>
          <BannerSlider
            page={page}
            banner={props.banners}
            backPage={backPage}
            calculateBannerSwipe={calculateBannerSwipe}
            lang={props.lang}
            modalControl={modalControl}
            nextPage={nextPage}
            setBannerSwipePos={setBannerSwipePos}
            setPage={setPage}
          />
        </div>
        <RcsClients
          lang={props.lang}
          className={`justify-center bg-white container ${styles.clients}`}
          rcsClient={props.rcsClient}
        />
        <RcsVideo onModalControl={modalControl} lang={props.lang} />
        <BenefitComponent lang={props.lang} />
        {/* <ValueProposition lang={props.lang} onModalControl={modalControl} /> */}
        <div
          id="features-section"
          className={`container ${styles.benefit_highlight}`}
        >
          <p style={{ marginBottom: "1.5em" }}>
            {homeStr.feature_title[props.lang]}
          </p>
          <div className={styles.tab_menu}>
            {benefitContent.map((bc, idx) => (
              <div
                key={idx}
                onClick={(e) => {
                  setBenefitPage(idx);
                  slideRef?.current?.goTo(idx);
                }}
                className={benefitPage === idx ? styles.active : ""}
              >
                <div>{bc.pageName}</div>
              </div>
            ))}
          </div>

          <div className={styles.benefit_content}>
            <div>
              <Slide
                ref={slideRef}
                duration={5000}
                infinite={false}
                autoplay={false}
                arrows={false}
                onChange={handleSlideChange}
                transitionDuration={200}
              >
                {benefitContent?.map((el, index) => (
                  <Image
                    key={index}
                    draggable={false}
                    onTouchStart={(e) =>
                      setBenefitSwipePos(e.changedTouches[0].screenX)
                    }
                    onTouchEnd={(e) =>
                      calculateSwipe(e.changedTouches[0].screenX)
                    }
                    src={el?.img}
                    alt={el?.img}
                    layout="intrinsic"
                    width={"1440px"}
                    height={"900px"}
                    objectFit="cover"
                    placeholder="blur"
                    blurDataURL={el?.img}
                  />
                ))}
              </Slide>
            </div>
            <div className={styles.website_ver}></div>
            <div className={styles.bullets}>
              {benefitContent.map((bc, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    setBenefitPage(idx);
                    slideRef?.current?.goTo(idx);
                  }}
                  className={`${styles.bullet} ${
                    benefitPage === idx ? styles.active : ""
                  }`}
                />
              ))}
            </div>

            <div>
              <img
                alt="arrow-left-circle-blue.png"
                className={`${styles.benefit_back} mobile`}
                src="/assets/arrow-left-circle-blue.png"
                onClick={() => backBenefitPage()}
              ></img>
              <img
                alt="arrow-right-circle-blue.png"
                className={`${styles.benefit_next} mobile`}
                src="/assets/arrow-right-circle-blue.png"
                onClick={() => nextBenefitPage()}
              ></img>
              <p>{benefitContent[benefitPage]?.title}</p>
            </div>

            <p
              dangerouslySetInnerHTML={{
                __html: benefitContent[benefitPage]?.description,
              }}
            ></p>

            <div className={styles.learnmore_text_cont}>
              <LearnMoreText title={benefitStr.learnMore[props.lang]} />
            </div>
          </div>
        </div>

        {/* Main Activity  */}
        <MainActivitySection lang={props.lang} solution={props.solution} />

        <div className={`${styles.how_we_can_help}`}>
          <div className={`container ${styles.use_case}`}>
            <div className="flex" style={{ justifyContent: "space-around" }}>
              <p>{homeStr.use_case_title[props.lang]}</p>
              <Link href="/use-case">
                <a>
                  <div className={`${styles.use_case_more_example}`}>
                    <p>{homeStr.use_case_more_example[props.lang]}</p>
                    <img src="assets/bigger-than.png" alt="arrow right" />
                  </div>
                </a>
              </Link>
            </div>

            <div className={styles.use_case_desc}>
              {homeStr.use_case_desc[props.lang]}
            </div>
            <div className={styles.use_case_box}>
              <div className={styles.use_case_img_container}>
                <Image
                  src={props.useCase.attributes.image.data.attributes.url}
                  alt="use case image"
                  width={"500%"}
                  height={"400%"}
                  objectFit="cover"
                  placeholder="blur"
                  blurDataURL={
                    props.useCase.attributes.image.data.attributes.url
                  }
                />
              </div>

              <div className={styles.use_case_ctn} style={{ flex: 1 }}>
                <div></div>
                <div className={styles.use_case_title_container}>
                  {props.useCase.attributes.title}&nbsp;
                  <Link href={`/use-case/${props.useCase.attributes.slug}`}>
                    <div>
                      <span className="mobile">
                        {homeStr.learnMore[props.lang]}
                      </span>
                    </div>
                  </Link>
                </div>

                <div className={styles.desktop_btn_learn_more}>
                  <div className={styles.use_case_btn_container}>
                    <Link href={`/use-case/${props.useCase.attributes.slug}`}>
                      <div className={`clear ${styles.use_case_btn} desktop`}>
                        <div>{homeStr.learnMore[props.lang]}</div>
                        <img src="assets/bigger-than.png" alt="arrow right" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Articles & insights */}
        <div className={`container ${styles.articles}`}>
          <div className="flex">
            <div
              className={styles.articles_title}
              dangerouslySetInnerHTML={{ __html: articleStr.title[props.lang] }}
            ></div>
            <Link href="/insights">
              <a className={styles.articles_show_more}>
                <span>{articleStr.getMore[props.lang]}</span>
                <img src="assets/bigger-than.png" alt="arrow right" />
              </a>
            </Link>
          </div>
          <p className={`${styles.article_desc}`}>
            {articleStr.desc[props.lang]}
          </p>
          <div className={`flex ${styles.article_list} ${getJustifyStyle()}`}>
            {props.articles.map((art: headline, idx: number) => {
              if (idx < 3) {
                return (
                  <Link
                    key={`art-${idx}`}
                    href={`/insights/${art.attributes.slug}`}
                  >
                    <a>
                      <div className={styles.article_box}>
                        <div>
                          <Image
                            src={art.attributes.image.data.attributes.url}
                            alt={`article image ${idx}`}
                            width="550%"
                            height="360%"
                            objectFit="cover"
                            placeholder="blur"
                            blurDataURL={
                              art.attributes.image.data.attributes.url
                            }
                          />
                        </div>
                        <div>{art.attributes.title}</div>
                        <div>{art.attributes.short_description}</div>
                      </div>
                    </a>
                  </Link>
                );
              }
            })}
          </div>
        </div>
        {/* Testimonials */}
        {props.testimonials.length > 0 && (
          <TestimonialsComponent
            testimonials={props.testimonials}
            lang={props.lang}
          />
        )}
        {/* Media Publication */}
        <div className={`container ${styles.media_publication_section}`}>
          <div className={`${styles.media_publication_title}`}>
            {homeStr.media_publication_title[props.lang]}
          </div>
          <div
            className={`${styles.media_publication_list_container} backandforth`}
          >
            <div className={`${styles.media_publication_list}`}>
              <a
                href="https://teknologi.bisnis.com/read/20220915/84/1577719/inilah-solusi-bagi-perusahaan-untuk-ukur-kepatuhan-hukum"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/bisniscom.png"}
                  alt="bisnis.com media publication"
                  className={`${styles.bisniscom_img}`}
                />
              </a>
              <a
                href="https://katadata.co.id/dinihariyanti/berita/6343d41d170f4/hukumonline-hadirkan-rcs-untuk-pacu-kepatuhan-hukum-pelaku-usaha"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/katadata.png"}
                  alt="katadata media publication"
                  className={`${styles.katadata_img}`}
                />
              </a>
              <a
                href="https://mediaindonesia.com/politik-dan-hukum/551523/perkembangan-bisnis-perlu-didukung-literasi-hukum-melalui-platform-digital"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/mediaindonesia.png"}
                  alt="mediaindonesia media publication"
                  className={`${styles.mediaindonesia_img}`}
                />
              </a>
              <a
                href="https://investor.id/business/319327/39reg-tech39-perkuat-literasi-hukum-dan-mendukung-bisnis"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/investorid.png"}
                  alt="investor.id media publication"
                  className={`${styles.investorid_img}`}
                />
              </a>
              <a
                href="https://swa.co.id/swa/trends/hukumonline-dorong-kemajuan-bisnis-di-indonesia-lewat-teknologi"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/swacoid.png"}
                  alt="swa.co.id media publication"
                  className={`${styles.swacoid_img}`}
                />
              </a>
              <a
                href="https://www.marketing.co.id/hukumonline-com-perkuat-literasi-hukum-lewat-teknologi"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/marketingcoid.png"}
                  alt="marketing.co.id media publication"
                  className={`${styles.marketingcoid_img}`}
                />
              </a>
              <a
                href="https://www.liputan6.com/regional/read/5184730/100-ribu-regulasi-di-indonesia-bagaimana-pelaku-industri-reg-tech-mengolahnya"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/liputan6.png"}
                  alt="liputan6 media publication"
                  className={`${styles.liputan6_img}`}
                />
              </a>
              <a
                href="https://teknologi.bisnis.com/read/20220915/84/1577719/inilah-solusi-bagi-perusahaan-untuk-ukur-kepatuhan-hukum"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/bisniscom.png"}
                  alt="bisnis.com media publication"
                  className={`${styles.bisniscom_img}`}
                />
              </a>
              <a
                href="https://katadata.co.id/dinihariyanti/berita/6343d41d170f4/hukumonline-hadirkan-rcs-untuk-pacu-kepatuhan-hukum-pelaku-usaha"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/katadata.png"}
                  alt="katadata media publication"
                  className={`${styles.katadata_img}`}
                />
              </a>
              <a
                href="https://mediaindonesia.com/politik-dan-hukum/551523/perkembangan-bisnis-perlu-didukung-literasi-hukum-melalui-platform-digital"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/mediaindonesia.png"}
                  alt="mediaindonesia media publication"
                  className={`${styles.mediaindonesia_img}`}
                />
              </a>
              <a
                href="https://investor.id/business/319327/39reg-tech39-perkuat-literasi-hukum-dan-mendukung-bisnis"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/investorid.png"}
                  alt="investor.id media publication"
                  className={`${styles.investorid_img}`}
                />
              </a>
              <a
                href="https://swa.co.id/swa/trends/hukumonline-dorong-kemajuan-bisnis-di-indonesia-lewat-teknologi"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/swacoid.png"}
                  alt="swa.co.id media publication"
                  className={`${styles.swacoid_img}`}
                />
              </a>
              <a
                href="https://www.marketing.co.id/hukumonline-com-perkuat-literasi-hukum-lewat-teknologi"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/marketingcoid.png"}
                  alt="marketing.co.id media publication"
                  className={`${styles.marketingcoid_img}`}
                />
              </a>
              <a
                href="https://www.liputan6.com/regional/read/5184730/100-ribu-regulasi-di-indonesia-bagaimana-pelaku-industri-reg-tech-mengolahnya"
                target="_blank"
                className={`${styles.media_publication_image}`}
                rel="noreferrer"
              >
                <img
                  src={"/assets/liputan6.png"}
                  alt="liputan6 media publication"
                  className={`${styles.liputan6_img}`}
                />
              </a>
            </div>
          </div>
        </div>
        <RequestDemo
          lang={props.lang}
          onModalControl={modalControl}
          requestDemoClassType={"gtm-requestDemo-section-homepage"}
        />
        <FloatWhatsapp lang={props.lang} />
        {bookModal ? (
          <BookDemoModal
            lang={props.lang}
            onSave={() => {}}
            onCancel={modalControl}
          />
        ) : (
          ""
        )}
      </main>

      <footer></footer>
    </div>
  );
};

export async function getServerSideProps({ locale }: any) {
  const lang = locale?.substring(0, 2);
  const headlineData = await getUseCaseHeadline(lang || "id");
  let headline: headline | null = headlineData.data.data[0];

  const dataClientLogos = await getClientLogos(lang || "id");

  const rcsClient: ClientsData[] = dataClientLogos.data.data.map(
    (item: any) => {
      return {
        client_image:
          item.attributes.client_image?.data?.attributes?.url || null,
        image_title:
          item.attributes.client_image?.data?.attributes?.alternativeText || "",
      };
    }
  );

  const dataArticles = await getHomeArticles(lang || "id");

  const dataBanners = await getBanners(lang || "id");

  dataArticles?.data?.data?.sort(
    (a: headline, b: headline) => a.attributes.order - b.attributes.order
  );
  dataBanners?.data?.data?.sort(
    (a: banner, b: banner) => a.attributes.order - b.attributes.order
  );

  const dataSolutionSection = await getMainActivity(lang || "id");

  const dataTestimonials = await getTestimonials();

  return {
    props: {
      rcsClient: rcsClient,
      useCase: headline,
      articles: dataArticles.data.data,
      banners: dataBanners.data.data,
      solution: dataSolutionSection.data.data,
      testimonials: dataTestimonials.data.data,
      lang: Languages.English,
    },
  };
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <MainLayout>{page}</MainLayout>;
};

export default Home;
