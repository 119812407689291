import rcsIntroStr from "@assets/strings/rcs_intro_vid.json"
import { Languages } from 'utils/datautils'
import Link from 'next/link'
import styles from '../styles/LearnMoreText.module.scss'

type MainProps = {
  title: string;
}

export default function ArticleDisplay({ title }: MainProps) {
  return (
    <Link href="/about">
      <a className={styles.learn_more}>
        <span>{title}</span>
        <img src="assets/bigger-than.png" alt="arrow right" />
      </a>
    </Link>
  )
  
}
