import Script from "next/script";
import Link from "next/link";
import LearnMoreText from "components/LearnMoreText";
import styles from "../styles/RcsVideo.module.scss";
import rcsIntroStr from "@assets/strings/rcs_intro_vid.json";
import { MouseEventHandler } from "react";
import { Languages } from "utils/datautils";

type RcsProps = {
  lang: Languages;
  onModalControl: MouseEventHandler<HTMLButtonElement>;
};

export default function RcsVideo({ lang, onModalControl }: RcsProps) {
  return (
    <>
      <Script
        src="https://apis.google.com/js/platform.js"
        strategy="lazyOnload"
      ></Script>

      <div id="explanation-section" className={`container ${styles.rcs_video}`}>
        <p className={styles.rcs_title}>{rcsIntroStr?.title[lang]}</p>
        <p
          className={styles.rcs_desc}
          dangerouslySetInnerHTML={{
            __html: rcsIntroStr?.section_subtitle[lang],
          }}
        ></p>
        {/* <button className={'gtm-requestDemo-explanation-homepage ' + styles.request_section_btn} onClick={onModalControl}>{rcsIntroStr.requestDemo[lang]}</button> */}
        <LearnMoreText title={rcsIntroStr.learnMore[lang]} />
        <div className={styles.rcs_youtube}>
          <iframe
            src="https://www.youtube.com/embed/fxzkp9odhQM?usageapi=1"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          {/* <span>
            <div className="g-ytsubscribe" data-channel="hukumonlinevideo" data-layout="full" data-theme="dark" data-count="default"></div>
          </span> */}
        </div>
      </div>
    </>
  );
}
