import React from "react";
import styles from "styles/main-activity/MainActivityCard.module.css";
import { Languages } from "utils/datautils";
import getConfig from "next/config";
const { serverRuntimeConfig } = getConfig();

type MainActivityCardProps = {
  activityName: string;
  activityIcon: string;
  activityIconHover: string;
  lang: Languages;
};

const MainActivityCard = (props: MainActivityCardProps) => {
  return (
    <div className={`${styles.activity_card}`}>
      <div className={`${styles.activity_icon}`}>
        <img
          src={props.activityIcon}
          alt="Icon"
          className={styles.activity_icon_image}
          style={{ visibility: "visible" }}
        />
        <img
          src={props.activityIconHover}
          alt="Icon"
          className={styles.activity_icon_image_hover}
          style={{ visibility: "visible" }}
        />
      </div>
      <div className={`${styles.activity_name}`}>{props.activityName}</div>
    </div>
  );
};

export default MainActivityCard;
