import { useState } from "react";
import { TestimonialsData, Languages } from "../utils/datautils";
import styles from "../styles/Home.module.scss";
import homeStr from "@assets/strings/home.json";

type TestimonialsComponentProps = {
  testimonials: TestimonialsData[];
  lang: Languages;
};

const TestimonialsComponent = ({
  testimonials,
  lang,
}: TestimonialsComponentProps) => {
  const [testimonialPos, setTestimonialPos] = useState(0);

  return (
    <div className={`container ${styles.testimonials} `}>
      <div className="flex">
        <div
          className={styles.testimonials_title}
          dangerouslySetInnerHTML={{
            __html: homeStr.testimonial_title[lang],
          }}
        />
      </div>
      <div className={`${styles.testimonial_subtitle}`}>
        {homeStr.testimonial_subtitle[lang]}
      </div>
      <div className={`${styles.testimonial_detail}`}>
        <div className={`${styles.testimonial_detail_left}`}>
          <img
            className={`${styles.testimonial_photo_bg}`}
            src="/assets/testimonial_side_photo.png"
            alt="background"
          />
          <img
            className={`${styles.testimonial_photo_user}`}
            src={
              testimonials[testimonialPos].attributes.photo_200px_x_200px.data
                .attributes.url
            }
            alt="testimonial_photo"
          />
        </div>
        <div className={`${styles.testimonial_detail_right}`}>
          <div className={`${styles.testimonial_detail_right_text}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: `${testimonials[testimonialPos].attributes.testimonial_text}`,
              }}
            />
          </div>
          <div className={`${styles.testimonial_detail_right_user}`}>
            <span>{`${testimonials[testimonialPos].attributes.name}, `}</span>
            {`${testimonials[testimonialPos].attributes.position} ${testimonials[testimonialPos].attributes.company}`}
          </div>
          <div className={`${styles.testimonial_pos_nav}`}>
            {testimonialPos < testimonials.length - 1 && (
              <div onClick={() => setTestimonialPos(testimonialPos + 1)}>
                {homeStr.next[lang]}
              </div>
            )}
            {testimonialPos > 0 && (
              <div onClick={() => setTestimonialPos(testimonialPos - 1)}>
                {homeStr.previous[lang]}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsComponent;
