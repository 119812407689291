import React from "react";
import { Languages } from "utils/datautils";
import valPropStr from "../public/assets/strings/value_proposition.json";
import styles from "../styles/main-activity/MainActivityDetail.module.css";
import MainActivityBenefitCard from "./MainActivityBenefitCard";

interface BenefitComponentProps {
  lang: Languages;
  //   benefits: BenefitData[]
}

type BenefitData = {
  icon: React.ReactNode;
  iconHover: React.ReactNode;
  title: string;
  desc: string;
};

const BenefitComponent = (props: BenefitComponentProps) => {
  const benefitsData: BenefitData[] = [
    {
      icon: (
        <img
          src={"/assets/expert_efficiency_color.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      iconHover: (
        <img
          src={"/assets/expert_efficiency.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      title: valPropStr.item_title1[props.lang],
      desc: valPropStr.item_desc1[props.lang],
    },
    {
      icon: (
        <img
          src={"/assets/expert_verified_color.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      iconHover: (
        <img
          src={"/assets/expert_verified.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      title: valPropStr.item_title2[props.lang],
      desc: valPropStr.item_desc2[props.lang],
    },
    {
      icon: (
        <img
          src={"/assets/expert_faster_color.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      iconHover: (
        <img
          src={"/assets/expert_faster.png"}
          alt="Icon"
          className={styles.benefit_icon}
        />
      ),
      title: valPropStr.item_title3[props.lang],
      desc: valPropStr.item_desc3[props.lang],
    },
  ];
  const benefits = benefitsData;

  return (
    <>
      {benefits.length > 0 ? (
        <div className={styles.benefit_section}>
          <p className={styles.benefit_section_title}>
            {valPropStr.benefit_section_title[props.lang]}
          </p>
          <div className={styles.benefit_section_content_wrapper}>
            {benefits.map((benefit, index) => (
              <MainActivityBenefitCard
                key={index}
                icon={benefit.icon}
                iconHover={benefit.iconHover}
                title={benefit.title}
                desc={benefit.desc}
                lang={props.lang}
              />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BenefitComponent;
